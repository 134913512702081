<template>
  <div class="py-4">
    <div class="container">
      <div class="lower mt-2 grid items-center gap-4 sm:grid-cols-2">
        <div class="max-sm:order-2">
          <p class="text-xs font-bold">
            &copy; {{ year }} Calibre Careers All Rights Reserved.
          </p>
        </div>

        <div
          class="flex gap-4 max-sm:order-1 max-sm:flex-col sm:items-center sm:justify-end sm:gap-7 sm:text-right"
        >
          <SiteSocialList class="flex items-center gap-5" />
          <SiteFooterLinks class="flex gap-5 text-xs font-bold uppercase" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const dayjs = useDayjs()

const year = dayjs().year()
</script>
