<template>
  <div>
    <div class="sticky top-0 z-20 bg-white">
      <SiteHeader />
    </div>
    <main>
      <LayoutContainer class="text-center">
        <h1 class="mt-28 text-4xl font-bold">{{ error.statusCode }}</h1>
        <p class="text-sm">{{ errorMessage }}</p>
        <button class="btn mt-6" @click="handleError">Back to homepage</button>
      </LayoutContainer>
    </main>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "#app"
import type { SiteSettings } from "~/@types/siteSettings"
import { groqSettings } from "~/groq/settings"
import favicons from "~/config/favicons.json"

const props = defineProps<{
  error: NuxtError
}>()

const { error } = toRefs(props)
const sanity = useSanity()

const handleError = () => clearError({ redirect: "/" })

const errorMessage = computed(() =>
  process.env.NODE_ENV === "development" && error.value.message
    ? error.value.message
    : error.value.statusMessage,
)

const { data: settings } = await useAsyncData<SiteSettings>(
  "site-settings",
  () => sanity.fetch(groqSettings()),
)

useSeoMeta({
  title: "Calibre Careers",
  ogTitle: "Calibre Careers",
  description: settings.value?.siteDescription,
})

useHead({
  htmlAttrs: {
    lang: "en",
  },
  link: [...favicons],
})
</script>
